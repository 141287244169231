var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',[_c('custom-vs-table',{ref:"table",attrs:{"pagination":"","stripe":"","sst":"","search":"","hoverFlat":"","data":_vm.lists.data,"max-items":_vm.lists.meta.pagination.per_page,"total":_vm.lists.meta.pagination.total},on:{"search":_vm.handleSearch,"change-page":_vm.handleChangePage}},[_c('div',{staticClass:"flex flex-wrap-reverse items-center flex-grow justify-between my-2",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"flex flex-wrap-reverse flex-grow items-center data-list-btn-container"},[_c('div',{staticClass:"w-full px-2 py-2 md:w-auto md:min-w-1/2 md:py-0"},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full min-w-full",attrs:{"name":"parent","color":"dark","clearable":false,"options":_vm.trackingTopicsOptions},on:{"input":function (selected) { return (_vm.params.parent_id = selected.value); }},model:{value:(_vm.filter.parent),callback:function ($$v) {_vm.$set(_vm.filter, "parent", $$v)},expression:"filter.parent"}})],1),_c('div',{staticClass:"w-full px-2 py-2 md:w-auto md:py-0"},[_c('vs-button',{attrs:{"color":"success","type":"border","icon":"icon-plus","icon-pack":"feather","to":{
              name: 'tracking-topic.new',
            }}},[_vm._v(" Add New ")])],1)])]),_c('template',{slot:"thead"},[_c('vs-th',{staticClass:"thead"},[_vm._v("No")]),_c('vs-th',{staticClass:"thead",attrs:{"sort-key":"name"}},[_vm._v("Title")]),(_vm.params.parent_id)?_c('vs-th',{staticClass:"thead"},[_vm._v("Parent")]):_vm._e(),_c('vs-th',{staticClass:"thead"},[_vm._v("Types")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Status")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Date")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Action")])],1),_vm._l((_vm.lists.data),function(trackingTopic,index){return _c('vs-tr',{key:index},[_c('vs-td',[_vm._v(" "+_vm._s(_vm.rowNo(index))+" ")]),_c('vs-td',{attrs:{"data":trackingTopic.title}},[_vm._v(" "+_vm._s(trackingTopic.title)+" ")]),(_vm.params.parent_id)?_c('vs-td',[_vm._v(" "+_vm._s(trackingTopic.parent)+" ")]):_vm._e(),_c('vs-td',[_c('cell-renderer-types',{attrs:{"types":trackingTopic.types}})],1),_c('vs-td',[_c('cell-renderer-status',{attrs:{"status":trackingTopic.is_active}})],1),_c('vs-td',[_c('span',[_vm._v(" "+_vm._s(_vm.$helpers.dateFormat(trackingTopic.created_at))+" ")])]),_c('vs-td',[_c('vs-button',{staticClass:"float-left",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-edit-2","to":{
              name: 'tracking-topic.edit',
              params: {
                id: trackingTopic.id,
              },
            }}}),_c('vs-button',{staticClass:"float-left",attrs:{"color":"danger","type":"filled","icon-pack":"feather","icon":"icon-trash-2"},on:{"click":function($event){$event.preventDefault();return _vm.remove(trackingTopic.id)}}})],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }