<template>
  <vx-card>
    <!-- table -->
    <custom-vs-table
      ref="table"
      pagination
      stripe
      sst
      search
      hoverFlat
      :data="lists.data"
      :max-items="lists.meta.pagination.per_page"
      :total="lists.meta.pagination.total"
      @search="handleSearch"
      @change-page="handleChangePage"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between my-2"
      >
        <div
          class="flex flex-wrap-reverse flex-grow items-center data-list-btn-container"
        >
          <div class="w-full px-2 py-2 md:w-auto md:min-w-1/2 md:py-0">
            <v-select
              v-model="filter.parent"
              name="parent"
              class="w-full min-w-full"
              color="dark"
              :clearable="false"
              :options="trackingTopicsOptions"
              @input="(selected) => (params.parent_id = selected.value)"
              v-validate="'required'"
            />
          </div>
          <div class="w-full px-2 py-2 md:w-auto md:py-0">
            <!-- ADD NEW -->
            <vs-button
              color="success"
              type="border"
              icon="icon-plus"
              icon-pack="feather"
              :to="{
                name: 'tracking-topic.new',
              }"
            >
              Add New
            </vs-button>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th class="thead">No</vs-th>
        <vs-th class="thead" sort-key="name">Title</vs-th>
        <vs-th class="thead" v-if="params.parent_id">Parent</vs-th>
        <vs-th class="thead">Types</vs-th>
        <vs-th class="thead">Status</vs-th>
        <vs-th class="thead">Date</vs-th>
        <vs-th class="thead">Action</vs-th>
      </template>

      <template>
        <vs-tr v-for="(trackingTopic, index) in lists.data" :key="index">
          <vs-td>
            {{ rowNo(index) }}
          </vs-td>
          <vs-td :data="trackingTopic.title">
            {{ trackingTopic.title }}
          </vs-td>
          <vs-td v-if="params.parent_id">
            {{ trackingTopic.parent }}
          </vs-td>
          <vs-td>
            <cell-renderer-types :types="trackingTopic.types" />
          </vs-td>
          <vs-td>
            <cell-renderer-status :status="trackingTopic.is_active" />
          </vs-td>
          <vs-td>
            <span>
              {{ $helpers.dateFormat(trackingTopic.created_at) }}
            </span>
          </vs-td>
          <vs-td>
            <vs-button
              class="float-left"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-edit-2"
              :to="{
                name: 'tracking-topic.edit',
                params: {
                  id: trackingTopic.id,
                },
              }"
            />
            <vs-button
              class="float-left"
              color="danger"
              type="filled"
              icon-pack="feather"
              icon="icon-trash-2"
              @click.prevent="remove(trackingTopic.id)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </custom-vs-table>
  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { default as listMixin } from '@/mixins/tracking-topic/listMixin'
import { default as formMixin } from '@/mixins/tracking-topic/formMixin'
import CustomVsTable from '@/components/table/CustomVsTable'
import CellRendererStatus from '@/components/table/cell-renderer/Status'
import CellRendererTypes from '@/components/table/cell-renderer/Types'

const parentDefault = () => ({ label: '- Root -', value: 0 })

export default {
  mixins: [listMixin, formMixin],
  components: {
    CustomVsTable,
    CellRendererStatus,
    CellRendererTypes,
  },
  data: () => ({
    filter: {
      parent: parentDefault(),
    },
    params: {
      parent_id: 0,
    },
  }),
  computed: {
    ...mapGetters('data', ['trackingTopics']),
    ...mapGetters('trackingTopic', ['lists']),

    trackingTopicsOptions () {
      if (this.lodash.get(this, 'trackingTopics', []).length === 0) {
        return []
      }

      const trackingTopics = this.lodash.concat(
        parentDefault(),
        this.trackingTopics.map((trackingTopic) => ({
          label: trackingTopic.title,
          value: trackingTopic.id,
        })),
      )

      return trackingTopics
    },
  },
  methods: {
    ...mapActions('trackingTopic', ['fetch', 'delete', 'reset']),

    async _fetch () {
      this.locked = true

      await this.fetch({ ...this.lodash.get(this, 'params', {}) })

      this.locked = false
    },
  },

  async mounted () {
    this._fetch()
  },

  watch: {
    'params.parent_id' () {
      this.lodash.set(this, 'params.search', '')
      this.lodash.set(this, 'params.page', 1)

      this._fetch()
    },
  },
}
</script>
