<template>
  <div>
    <vs-chip
      class="ag-grid-cell-chip"
      :color="typeColor(type)"
      v-for="(type, index) in types"
      :key="index">
      <span>{{ typeText(type) }}</span>
    </vs-chip>
  </div>
</template>

<script>
export default {
  name: 'CellRendererTypes',
  props: {
    types: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    typeText (type) {
      return this.lodash.capitalize(type)
    },
    typeColor () {
      return 'warning'
    },
  },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
