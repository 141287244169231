import Vue from 'vue'

export default {
  name: 'CustomVsTable',
  mixins: [Vue.options.components.VsTable],
  methods: {
    loadData () {
      const max = Math.ceil(this.currentx * this.maxItemsx)
      const min = max - this.maxItemsx
      if (!this.searchx || this.sst) {
        this.datax = this.sortItems(this.data) || []
      } else {
        this.datax = this.getItemsSearch(min, max) || []
      }
    },
  },
  computed: {
    getTotalPages () {
      return Math.ceil(this.total / this.maxItemsx)
    },
  },
}
